import { useStaticQuery, graphql } from 'gatsby'

export const useContactData = lang => {
  const { page } = useStaticQuery(
    graphql`
      query {
        page: allWordpressPage(filter: { wordpress_id: { eq: 1109 } }) {
          edges {
            node {
              wordpress_id
              slug
              title
              content
              polylang_translations {
                polylang_current_lang
                acf {
                  #header
                  header_title
                  header_subtitle
                  email_contact
                  whatsapp_field
                  barcelona_ubication_header
                  barcelona_ubication_description
                  london_ubication_header
                  london_ubication_subtitle
                  form_first_label
                  form_second_label
                  form_third_label
                  form_four_label
                  form_five_label
                  form_seven_label
                  form_six_label
                  send_button_text
                  #press
                  press_title
                  press_email
                  press_button_text
                  first_press_text
                  first_press_date
                  first_press_link
                  second_press_text
                  second_press_date
                  second_press_link
                }
                yoast_meta {
                  yoast_wpseo_title
                  yoast_wpseo_metadesc
                }
              }
            }
          }
        }
      }
    `
  )
  return page.edges[0].node.polylang_translations.find(
    item => item.polylang_current_lang == lang
  )
}
