import { useStaticQuery, graphql } from 'gatsby'
export const useLandingData = lang => {
  const { page } = useStaticQuery(
    graphql`
      query {
        page: allWordpressPage(filter: { wordpress_id: { eq: 1105 } }) {
          edges {
            node {
              wordpress_id
              slug
              title
              content
              polylang_translations {
                polylang_current_lang
                yoast_meta {
                  yoast_wpseo_title
                  yoast_wpseo_metadesc
                }
                acf {
                  #header
                  header_botonic_text
                  header_botonic_cta
                  header_banner
                  header_title
                  header_subtitle
                  header_bullet_point_1
                  header_bullet_point_2
                  header_bullet_point_3
                  header_bullet_point_4
                  header_bullet_point_5
                  header_apply_cta
                  header_apply_cta_hint
                  #automation
                  automation_title
                  automation_bullet_point_1 {
                    description
                  }
                  automation_bullet_point_2 {
                    description
                  }
                  automation_bullet_point_3 {
                    description
                  }
                  automation_bullet_point_4 {
                    description
                  }
                  automation_bullet_point_5 {
                    description
                  }
                  automation_bullet_point_6 {
                    description
                  }
                  #businesses
                  business_title
                  business_quote_1 {
                    text
                    customer_name
                    customer_position
                  }
                  business_quote_2 {
                    text
                    customer_name
                    customer_position
                  }
                  #enterprise
                  enterprise_title
                  enterprise_tagline
                  enterprise_text
                  #early_access
                  early_access_title
                  early_access_text
                  early_access_button
                  early_access_button_hint
                  #use_cases
                  use_cases_title
                  use_cases_1 {
                    title
                    text
                  }
                  use_cases_2 {
                    title
                    text
                  }
                  use_cases_3 {
                    title
                    text
                  }
                  #channels interested
                  channels_interested {
                    title
                    channels {
                      whatsapp
                      facebook
                      telegram
                      webchat
                      other
                    }
                  }
                  #form
                  form_title
                  form_subtitle
                  form_right_question
                  form_right_text
                  first_name
                  last_name
                  email
                  mail_error
                  phone
                  job_title
                  company
                  country
                  radio_1 {
                    title
                    option_1
                    option_2
                  }
                  radio_2 {
                    title
                    subtitle
                    option_1
                    option_2
                  }
                  radio_3 {
                    title
                    option_1
                    option_2
                  }
                  intended_use_selector {
                    title
                    option_1
                    option_2
                    option_3
                    option_4
                  }
                  traffic_volume_selector {
                    title
                    option_1
                    option_2
                    option_3
                    option_4
                    option_5
                    option_6
                    option_7
                  }
                  est_mau_selector {
                    title
                    option_1
                    option_2
                    option_3
                    option_4
                    option_5
                  }
                  support_people_selector {
                    title
                    subtitle
                    option_1
                    option_2
                    option_3
                    option_4
                    option_5
                  }
                  message_tagline
                  message_placeholder
                  i_agree
                  privacy_policy
                  sent_feedback
                  message_feedback_text
                  message_feedback_text_uncomplete
                  send_button
                  choose_option
                }
              }
            }
          }
        }
      }
    `
  )
  return page.edges[0].node.polylang_translations.find(
    item => item.polylang_current_lang == lang
  )
}
