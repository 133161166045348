import { useStaticQuery, graphql } from 'gatsby'
export const useHomeData = lang => {
  const { page } = useStaticQuery(
    graphql`
      query {
        page: allWordpressPage(filter: { wordpress_id: { eq: 101 } }) {
          edges {
            node {
              wordpress_id
              slug
              title
              content
              polylang_translations {
                polylang_current_lang
                yoast_meta {
                  yoast_wpseo_title
                  yoast_wpseo_metadesc
                }
                acf {
                  #header
                  whatsapp_banner
                  whatsapp_banner_learnMore
                  header_title
                  header_title_mobile
                  header_subtitle
                  header_subtitle_mobile
                  #messaging Conversations
                  messaging_title
                  messaging_title_mobile
                  left_content_block
                  bottom_content_block
                  bottom_content_block_mobile
                  right_content_block
                  right_content_block_mobile
                  button_start_conversation
                  path_button_start_conversation
                  button_developer
                  path_button_developer
                  #HowWorks
                  title_work
                  columns {
                    column_one {
                      title
                      logo {
                        localFile {
                          childImageSharp {
                            fixed(width: 35, height: 35) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                    column_two {
                      title
                      logo {
                        localFile {
                          childImageSharp {
                            fixed(width: 35, height: 35) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                    column_three {
                      title
                      logo {
                        localFile {
                          childImageSharp {
                            fixed(width: 35, height: 35) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                    column_four {
                      title
                      logo {
                        localFile {
                          childImageSharp {
                            fixed(width: 35, height: 35) {
                              ...GatsbyImageSharpFixed
                            }
                          }
                        }
                      }
                      desc
                    }
                  }
                  #customers
                  customers_title
                  customers_description1
                  customers_description2
                  customers_title_mobile
                  customers_description_mobile
                  group1_logos {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  group2_logos {
                    localFile {
                      childImageSharp {
                        fluid(maxWidth: 1000, quality: 100) {
                          ...GatsbyImageSharpFluid
                        }
                      }
                    }
                  }
                  logos {
                    logo1 {
                      localFile {
                        childImageSharp {
                          fixed(width: 100, height: 42) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    logo2 {
                      localFile {
                        childImageSharp {
                          fixed(width: 60, height: 60) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    logo3 {
                      localFile {
                        childImageSharp {
                          fixed(width: 200, height: 42) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    logo4 {
                      localFile {
                        childImageSharp {
                          fixed(width: 285, height: 42) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    logo5 {
                      localFile {
                        childImageSharp {
                          fixed(width: 60, height: 60) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    logo6 {
                      localFile {
                        childImageSharp {
                          fixed(width: 200, height: 50) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    logo7 {
                      localFile {
                        childImageSharp {
                          fixed(width: 220, height: 42) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                  }
                  #benefits
                  benefit1 {
                    title
                    pic {
                      localFile {
                        childImageSharp {
                          fixed(width: 81, height: 81) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    description
                  }
                  benefit2 {
                    title
                    pic {
                      localFile {
                        childImageSharp {
                          fixed(width: 81, height: 81) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    description
                  }
                  benefit3 {
                    title
                    pic {
                      localFile {
                        childImageSharp {
                          fixed(width: 81, height: 81) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    description
                  }
                  #useCases
                  use_case1 {
                    title
                    pic {
                      localFile {
                        childImageSharp {
                          fixed(width: 65, height: 65) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    description
                    buttonPath
                  }
                  use_case2 {
                    title
                    pic {
                      localFile {
                        childImageSharp {
                          fixed(width: 65, height: 65) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    description
                    buttonPath
                  }
                  use_case3 {
                    title
                    pic {
                      localFile {
                        childImageSharp {
                          fixed(width: 65, height: 65) {
                            ...GatsbyImageSharpFixed
                          }
                        }
                      }
                    }
                    description
                    buttonPath
                  }
                  #services
                  title
                  service1
                  service2
                  service3
                  text_aboveTitle
                  service_button
                }
              }
            }
          }
        }
      }
    `
  )
  return page.edges[0].node.polylang_translations.find(
    item => item.polylang_current_lang == lang
  )
}
